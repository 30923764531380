<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/4-1400.jpg')" title="RA-AUS PASSENGER CARRYING ENDORSEMENT" subtitle="A perfect fit" />
    <div class="program-description">
      <h1>What is the Passenger Carrying Endorsement?</h1>
      <p>
        This endorsement allows you to share your flying experience with your friends and family. With this, you can take 1 passenger up with you when you fly in an RA-Aus registered aircraft.
      </p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
